/**
 * 用来保存全局常量
 * 模型服务器配置
 */
let BaseURL='';
let ModelConfig,EngineConfig,ShareConfig;

 //模型列表 模型服务器配置
 ModelConfig = {
  //中间件官网正式
  serverIp : 'https://model.everybim.net',
  appKey : 'YHeLIehn9wNacNT2YZ08ggqM3HhldCF2X1Ng',
  appSecret : '818137e741bf3ab769c9266afd42165633f11f3137bd632b62fc70e56608b943'
};

//示例模型 模型服务器配置
EngineConfig ={
    serverIp : 'https://engine.everybim.net',
    appKey :'hw24HWfcBg3sc3PQZUTIRc5OervojWrbTHvK',
    appSecret : '9645a8210f85867d1d2248959a42b34fea4117513c9c4c98a1ce4aae6cef3b52'   
};

//打包 server 置空
let server = '';
//let server = 'https://model.everybim.net'; //正式服(模型列表)

let APIURL = `${server}/api`;

// 1. 中间件官网打包：设置isDemo = false 即中间件官网; 
// 2. 给客户打包：设置isDemo = true 只展示模型列表（模型功能）。
let isDemo = false;

module.exports = {
  baseURL: BaseURL,
  ApiURL: APIURL,
  isDemo:isDemo,
  modelConfig: ModelConfig,
  shareConfig: ShareConfig,
  engineConfig: EngineConfig,
}