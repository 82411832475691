import React, { PureComponent } from 'react'
import { Layout } from 'antd'
import { Route, Switch, BrowserRouter,HashRouter } from 'react-router-dom'
import {routers} from './routers'
import Footer from '../common/component/Footer'
import Header from '../common/component/Header'
import ScrollToTop from "./withRouter"

export default class App extends PureComponent {

  render() {
    return (
      <BrowserRouter>
        <Layout>
          <Switch>
            <ScrollToTop>
              {
                routers.map(({ path, exact, component: Component }) => (
                  <Route
                    key={path}
                    path={path}
                    exact={true}
                    render={(props) => {
                      return (
                        <Layout>
                          <Header />
                          <Component {...props} />
                          <Footer />
                        </Layout>
                      )
                    }}
                  />
                ))
              }
             </ScrollToTop>
          </Switch>
        </Layout>
      </BrowserRouter>
    )
  }
}

