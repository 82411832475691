import { AsyncComponent } from '../common/utils/AsyncComponent'

export const routers = [
    {
        path: '/',
        exact: true,
        isNav:true,
        title: '首页',
        component: AsyncComponent(() => import("../home")),
    },
    {
        path: '/example',
        exact: true,
        isNav:true,
        title: '示例',
        component: AsyncComponent(() => import("../example")),
    },
    {
        path: '/model',
        exact: true,
        title: '查看模型',
        component: AsyncComponent(() => import("../model")),
    },
    {
        path: '/drawing/:id',
        exact: true,
        title: '查看图纸',
        component: AsyncComponent(() => import("../drawing")),
    },
    {
        path: '/function',
        exact: true,
        isNav:true,
        title: '功能',
        component: AsyncComponent(() => import("../function")),
    },
    // {
    //     path: '/develop',
    //     exact: true,
    //     isNav:true,
    //     title: '开发',
    //     component: AsyncComponent(() => import("../develop")),
    // },
    {
        path: '/downloads',
        exact: true,
        isNav:true,
        title: '下载',
        component: AsyncComponent(() => import("../download")),
    },
    {
        path: '/about',
        exact: true,
        isNav:true,
        title: '关于',
        component: AsyncComponent(() => import("../about")),
    },
    {
        
        path: '/modelList',
        exact: true,
        isNav:true,
        isDemoShow:true,
        title: '模型',
        component: AsyncComponent(() => import("../modelList")),
    },
    // { 
    //     path: '/drawingList',
    //     exact: true,
    //     isNav:true,
    //     isDemoShow:true,
    //     title: '图纸列表',
    //     component: AsyncComponent(() => import("../drawingList")),
    // },
];