import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Dropdown, Icon, Drawer } from 'antd';
import { routers } from '../../../router/routers'
import navLogo from '../../img/common/nav_logo.png';
import {isDemo} from '../../config/websiteConfig';
import './header.less'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showHeader: true,
      activeKey: '',
      currentCity: {},
      navLink: [],
      menuVisible:false,
    }
    this.pathname = window.location.pathname;

    this.onShowMenu = () => {
      this.setState({
        menuVisible: true
      })
    };

    this.onHiddenMenu = () => {
      this.setState({
        menuVisible: false
      })
    };

    this.handleClick = (menuItem) => {
      this.props.history.replace(menuItem.key)
    };

  }
  componentDidMount() {
    // HashRouter
    // let hashRoot = window.location.hash.split("#/")[1];
    // let hashRootPath = hashRoot.split("/")[0];
    // if (hashRootPath === 'model'|| hashRootPath === 'drawing') {
    //   this.setState({
    //     showHeader: false,
    //   })
    // }

    // BrowserRouter
    if (this.pathname === '/model' || this.pathname === '/drawing') {
      this.setState({
        showHeader: false,
      })
    }

    //是否为 demo包 显示
    let orderLink = [];
    if(!isDemo){
      orderLink = routers.filter(item => item.isNav);
    }else{
      orderLink = routers.filter(item => item.isDemoShow);
    }
  
    this.setState({
      navLink: orderLink,
      navSpecial: false,
    })
  }

  render() {
    const { showHeader, navLink, menuVisible } = this.state;

    // let hashRoot = window.location.hash.split("#/")[1];
    // let hashRootPath = hashRoot.split("/")[0];
    // let activeKey = hashRootPath;

    // BrowserRouter
    let hashRoot = window.location.pathname.slice(1);
    let activeKey = hashRoot.indexOf('/') === -1 ? hashRoot : hashRoot.slice(0, hashRoot.indexOf('/'));

    return (<div>
      {showHeader ?
        <div className="header">
            <div className="nav-wrapper w1200">
              {/* PC 端 */}
              <Link to={'/'}>
                {
                  !isDemo ?
                  <div className="nav-front">
                     <img src={navLogo} alt="中间件官网Logo" className={`nav-logo`} />
                  </div>:null
                }
              </Link>
              <div className={`nav-link ${isDemo?'demoNav':''}`}>
                  {
                    navLink && navLink.map(item =>
                      <div key={item.title} className="nav-button">
                        {
                          item.isNav ? <Link to={item.path} className={"/" + activeKey === item.path ? "active" : ""} title={item.title}>{item.title}</Link> :
                            <span>{item.title}</span>
                        }
                      </div>)
                  }
              </div>
              {/* 移动端适配 */}
              <div className="nav-menu" onClick={() => menuVisible ? this.onHiddenMenu() : this.onShowMenu()}>
                     <Icon type={menuVisible ? "close" : "menu"} />
              </div>
              <Drawer
                title="Basic Drawer"
                placement="right"
                width={240}
                headerStyle={{ display: "none" }}
                bodyStyle={{ padding: "75px 0 15px 0" }}
                closable={false}
                onClose={this.onHiddenMenu}
                visible={menuVisible}
                getContainer={this.props.drawerContainerId ? document.getElementById(this.props.drawerContainerId) : 'body'}
              >
                <Menu
                  onClick={this.handleClick}
                  style={{ width: "100%" }}
                  defaultSelectedKeys={["/"]}
                  selectedKeys={["/" + AnalysisSubMenuActiveKey(hashRoot)]}
                  mode="inline"
                >
                  {
                    navLink && navLink.map(item => {
                      return <Menu.Item key={item.path}>{item.title}</Menu.Item>
                    })
                  }
                </Menu>
              </Drawer>
            </div>
        </div> : null
      }

    </div>)
  }
}

function AnalysisSubMenuActiveKey(hashRootPath) {
  let root = hashRootPath.split("/")[0];
  return root;
}

export default withRouter(Header);

