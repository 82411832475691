import React, { Component } from 'react'
import {isDemo} from '../../config/websiteConfig';
import './footer.less'

export default class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showFooter: true,
            isModelList:false
        }
        this.pathname = window.location.pathname
    }
    componentDidMount() {

        // BrowserRouter
        if (this.pathname == '/model' || this.pathname === '/drawing') {
            this.setState({
                showFooter: false,
            })
        }
        //移动端 模型列表隐藏底部
        if(this.pathname == '/modelList'){
            this.setState({
                isModelList: true,
            })
        }

        // HashRouter
        // let hashRoot = window.location.hash.split("#/")[1];
        // let hashRootPath = hashRoot.split("/")[0];
        // if (hashRootPath === 'model' || isDemo) {
        //   this.setState({
        //     showFooter: false,
        //   })
        // }
    }
    render() {
        const {showFooter, isModelList} =this.state;
        return (
            <div>
                {
                    showFooter ?
                        <div className={`footer ${isModelList ?'isShowFooter':''}`}>
                            <div className="footer-wrapper">
                                <div className="copyright">Copyright @ 2019-2022 EveryBIM 中间件</div>
                                <div className="record">京ICP备1009-22156 译筑信息科技（上海）有限公司</div>
                            </div>
                        </div> : ''
                }
            </div>
        )

    }

}